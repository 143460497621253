import { styled } from "@mui/material";
import clsx from "clsx";

const TabButtonStyled = styled("button")({
  transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  backgroundColor: "transparent",
  textTransform: "none",
  fontWeight: "500",
  whiteSpace: "nowrap",
  padding: "4px 8px",
  cursor: "pointer",
  "&.selected": {
    fontWeight: "600",
    backgroundColor: "transparent",
  },
  "&.tabs-component-btn": {
    border: "1px solid #1976d280",
    borderRadius: "4px",
    borderColor: "#e6e6e6",
    color: "#4d4d4d",
    "&:hover": {
      borderColor: "#96b6ed",
      color: "#5085e0",
    },
    "&.selected": {
      borderColor: "#5085e0",
      color: "#5085e0",
    },
  },
  "&.tabs-component-link": {
    border: "none",
    borderRadius: 0,
    borderBottom: "2px solid transparent",
    color: "#b5b3b3",
    "&:hover": {
      borderBottom: "2px solid #a4c2a3",
      color: "#898384",
    },
    "&.selected": {
      borderBottom: "2px solid #4f8b4d",
      color: "black",
    },
  },
});

const btnClassnameVariants = {
  outlined: "tabs-component-btn",
  underline: "tabs-component-link",
};

const TabButton = ({
  children,
  onClick,
  isSelected,
  variant,
  classNames = {},
}) => {
  return (
    <TabButtonStyled
      type="button"
      className={clsx(btnClassnameVariants[variant], classNames.button, {
        selected: isSelected,
        [classNames.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {children}
    </TabButtonStyled>
  );
};

export default TabButton;
