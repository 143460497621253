import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useActionChoices } from "stores";
import { FormSelectInput } from "form";
import { OPERATIONS } from "constants/actions";
import { actionInputName } from "../../utils/inputName";
import DetailSection from "../DetailSection";

const OtherActionOperationType = ({ isViewMode, isProposed }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { isSpecificOperation, getOperationById } = useActionChoices();

  const selectedOperationTypeId = useMemo(
    () => values.otherAction?.operationTypeId,
    [values.otherAction?.operationTypeId]
  );

  const operationTypeVariants = useMemo(() => {
    const operation = getOperationById(selectedOperationTypeId, true);
    return operation?.typeVariants || [];
  }, [getOperationById, selectedOperationTypeId]);

  const isPlowing = useMemo(
    () =>
      isSpecificOperation(OPERATIONS.OTHER.PLOWING, selectedOperationTypeId),
    [isSpecificOperation, selectedOperationTypeId]
  );

  const isMulch = useMemo(
    () => isSpecificOperation(OPERATIONS.OTHER.MULCH, selectedOperationTypeId),
    [isSpecificOperation, selectedOperationTypeId]
  );

  if (isPlowing) {
    return (
      <DetailSection title={t("ActionModal.PlowingType")}>
        <FormSelectInput
          name={actionInputName("otherAction", "ploughingType", isProposed)}
          options={operationTypeVariants}
          isViewMode={isViewMode}
          shouldHaveErrorBehaviorAfterSubmit
        />
      </DetailSection>
    );
  }

  if (isMulch) {
    return (
      <DetailSection title={t("ActionModal.MulchType")}>
        <FormSelectInput
          name={actionInputName("otherAction", "mulchType", isProposed)}
          options={operationTypeVariants}
          isViewMode={isViewMode}
          shouldHaveErrorBehaviorAfterSubmit
        />
      </DetailSection>
    );
  }

  return null;
};

export default OtherActionOperationType;
