export class MyFarmersDto {
  static parse(data) {
    return data.map((farmer) => {
      const registeredKydUser = !(
        Boolean(farmer?.user?.lastLogin) ||
        farmer?.user?.mobileVerified ||
        farmer.connectedToOpekepe ||
        farmer.syncedToOpekepe
      );
      let status = "registeredKydUser";
      if (farmer.syncedToOpekepe) {
        status = "syncedFields";
      } else if (farmer.connectedToOpekepe) {
        status = "opekepe";
      } else if (farmer?.user?.mobileVerified) {
        status = "otp";
      } else if (Boolean(farmer?.user?.lastLogin)) {
        status = "camvioConnection";
      }
      return {
        id: farmer?.user?.id,
        lastName: farmer?.user?.lastName || "",
        firstName: farmer?.user?.firstName || "",
        taxNumber: farmer?.user?.taxNumber || "",
        phone: farmer?.user?.phone || "",
        email: farmer?.user?.email || "",
        camvioConnection: Boolean(farmer?.user?.lastLogin),
        otp: farmer?.user?.mobileVerified,
        opekepe: farmer.connectedToOpekepe,
        syncedFields: farmer.syncedToOpekepe,
        registeredKydUser,
        status,
      };
    });
  }
}
