import clsx from "clsx";
import CardContent from "./CardSectionContent";
import CardSection from "./CardSection";
import CardTitle from "./CardSectionTitle";
import "./card.css";

const Card = ({ children, style = {}, className }) => (
  <div style={style} className={clsx("card-component-card", className)}>
    {children}
  </div>
);

export default Card;

Card.Section = CardSection;
Card.SectionTitle = CardTitle;
Card.SectionContent = CardContent;
