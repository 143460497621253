import {
  autocompleteClasses,
  inputBaseClasses,
  Autocomplete as MuiAutocomplete,
  outlinedInputClasses,
  Popper,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextFieldCustom from "../TextFieldCustom/TextFieldCustom";
import ListboxComponent from "./ListBoxComponent";

const MuiAutocompleteStyled = styled(MuiAutocomplete)({
  [`& .${autocompleteClasses.endAdornment}`]: {
    padding: 0,
  },
  [`& .${autocompleteClasses.inputRoot}.${inputBaseClasses.root}`]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  [`& .${autocompleteClasses.input}.${inputBaseClasses.input}.${outlinedInputClasses.input}`]:
    {
      paddingTop: 0,
      paddingBottom: 0,
    },
  [`.${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    fontSize: "14px",
  },
});

const MuiPopperStyled = styled(Popper)({
  [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
    fontSize: "14px",
  },
});

const viewModeStyle = {
  [`& .${autocompleteClasses.endAdornment}`]: {
    display: "none",
  },
};

const Autocomplete = ({
  className,
  helperText,
  id,
  isError,
  isMultiple,
  isDisabled,
  isViewMode,
  onChange,
  options,
  placeholder,
  label,
  value,
  sx,
}) => {
  const { t } = useTranslation();
  const placeholderFinal =
    placeholder || t("Components.Autocomplete.Placeholder");

  const handleChange = (e, newValue) => {
    onChange?.(newValue);
  };

  const finalSx = isViewMode ? { ...viewModeStyle, ...sx } : sx;

  return (
    <MuiAutocompleteStyled
      id={id}
      fullWidth
      value={value}
      multiple={isMultiple}
      disabled={isViewMode || isDisabled}
      onChange={handleChange}
      options={options}
      PopperComponent={MuiPopperStyled}
      ListboxComponent={ListboxComponent}
      getOptionDisabled={(option) => option.isDisabled}
      getOptionLabel={(option) =>
        option.label ?? options?.find((o) => o.value === option)?.label
      }
      renderInput={(params) => {
        return (
          <TextFieldCustom
            {...params}
            label={label}
            placeholder={placeholderFinal}
            helperText={helperText}
            isError={isError}
            isViewMode={isViewMode}
          />
        );
      }}
      renderOption={(props, option, state) => [props, option, state.index]}
      className={className}
      sx={finalSx}
    />
  );
};

export default Autocomplete;
