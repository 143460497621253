import { FormikProvider } from "formik";
import { ActionCause } from "components";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import AnalysisHeader from "../header/analysis-header/AnalysisHeader";
import AnalysisBaseInfo from "../base-info/AnalysisBaseInfo";
import { useAnalysisFormikSetup } from "../utils/useFormikSetup";
import AnalysisDetails from "../details/analysis/AnalysisDetails";
import { ActionReadyFormStyled } from "../common-styled/ActionReadyFormStyled";

const AnalysisReadyContent = ({ selectedField, onClose, type }) => {
  const { formik, isLoading } = useAnalysisFormikSetup({
    selectedField,
    onClose,
    type,
  });
  return (
    <FormikProvider value={formik}>
      <ActionReadyFormStyled onSubmit={formik.handleSubmit}>
        <AnalysisHeader isLoading={isLoading} />
        <div className="action-modal-form-inner">
          <div className="action-modal-card">
            <div className="action-modal-top-box">
              <AnalysisBaseInfo
                // viewMode={action.type}
                viewMode={ACTION_VIEW_MODE.new}
              />
            </div>
            <div className="action-modal-top-box">
              <ActionCause
                // viewMode={action.type}
                viewMode={ACTION_VIEW_MODE.new}
              />
            </div>
          </div>

          <div className="action-details-wrapper">
            <AnalysisDetails
              // viewMode={action.type}
              viewMode={ACTION_VIEW_MODE.new}
            />
          </div>
        </div>
      </ActionReadyFormStyled>
    </FormikProvider>
  );
};

export default AnalysisReadyContent;
