import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { api } from "api";

const AgrochemichalsContext = createContext({
  activeSubstances: {},
  isLoading: false,
  error: null,
});

const transformActiveSubstances = (data) => {
  const substances = {};
  if (!Array.isArray(data)) {
    return {};
  }
  data.forEach((substance) => {
    const { agrochemicalRead } = substance;
    if (!agrochemicalRead || !agrochemicalRead.pesticideId) {
      return;
    }
    const {
      // name: { nameEn },
      pesticideId,
    } = agrochemicalRead;

    substances[pesticideId] = Array.isArray(substances[pesticideId])
      ? [...substances[pesticideId], substance]
      : [substance];
  });
  return substances;
};

const AgrochemichalsProvider = ({ children, cropId }) => {
  const [activeSubstances, setActiveSubstances] = useState();
  const [fetchState, setFetchState] = useState({
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    if (!cropId) {
      setFetchState(() => ({
        isLoading: false,
        error: "No cropId",
      }));
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async (id) => {
      try {
        const { data } = await api.crops.activeSubstances(id, { signal });
        if (data.length === 0 && id !== undefined) {
          await fetchData();
        } else {
          const activeSubstancesDict = transformActiveSubstances(data);
          setActiveSubstances(activeSubstancesDict);
          setFetchState(() => ({ isLoading: false }));
        }
      } catch (err) {
        setFetchState(() => ({
          isLoading: false,
          error: err,
        }));
      }
    };
    fetchData(cropId);

    return () => {
      controller.abort();
    };
  }, [cropId]);

  const values = useMemo(
    () => ({
      activeSubstances,
      ...fetchState,
    }),
    [activeSubstances, fetchState]
  );

  return (
    <AgrochemichalsContext.Provider value={values}>
      {children}
    </AgrochemichalsContext.Provider>
  );
};

const useAgrochemicalsContext = () => {
  const context = useContext(AgrochemichalsContext);

  if (!context) {
    throw new Error(
      "useAgrochemicalsContext must be used within a AgrochemichalsProvider"
    );
  }
  return context;
};

export { AgrochemichalsProvider, useAgrochemicalsContext };
