import { styled } from "@mui/material";
import clsx from "clsx";
import TabButton from "./TabButton";

const TabsStyled = styled("div")({
  "&.tabs-component-wrapper": {
    display: "flex",
    gap: "4px",
  },
});

const Tabs = ({
  value,
  setValue,
  tabs = [],
  classNames = {},
  className,
  variant = "outlined", // 'outlined' | 'underline'
}) => {
  const { wrapper: wrapperClassname, ...btnClassnames } = classNames;
  return (
    <TabsStyled
      className={clsx("tabs-component-wrapper", wrapperClassname, className)}
    >
      {tabs.map((tab) => (
        <TabButton
          key={tab.value}
          isSelected={tab.value === value}
          variant={variant}
          classNames={btnClassnames}
          onClick={() => setValue(tab.value)}
        >
          {tab.label}
        </TabButton>
      ))}
    </TabsStyled>
  );
};

export default Tabs;
