// Import Swiper React components
import clsx from "clsx";
import { Swiper as LibSwiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./my-swiper.css";

const Swiper = ({ spaceBetween, slidesPerView, render, items = [] }) => {
  return (
    <LibSwiper
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      mousewheel
      pagination
      modules={[Mousewheel, Pagination]}
      className="my-swiper"
    >
      {items.map((item) => (
        <SwiperSlide
          key={item.id}
          className={clsx({
            "my-swiper-slide follow-content": slidesPerView === "auto",
          })}
        >
          {render(item)}
        </SwiperSlide>
      ))}
    </LibSwiper>
  );
};
export default Swiper;
