export const ACTION = {
  watering: "watering",
  drug: "drug",
  fertilize: "fertilize",
  diagnose: "diagnose",
  other: "other",
};

export const OPERATIONS = {
  WATERING: {
    SPRINKLERS: "sprinklers", // Αυτοπροωθούμενα συστήματα καταιονισμού
    SUB_SURFACE_IRRIGATION: "subSurfaceIrrigation", // Υπόγειοι συστήματα άρδευσης
    DROP_WATERING: "dropWatering", // Πότισμα στάγδην
    ARTIFICIAL_RAIN: "artificialRain",
    DROP_MINI_SPRINKLERS: "dropMiniSprinklers", // Μικροεκτοξευτήρες
    SURFACE_IRRIGATION: "surfaceIrrigation", // Επιφανειακή άρδευσης
    OTHER: "other",
  },
  DRUG: {
    PLANT_PROTECTION_NORMAL: "plantProtectionNormal", // Φυτοπροστασία με ακροφύσια κανονικής διασποράς
    PLANT_PROTECTION_REDUCED: "plantProtectionReduced", // Φυτοπροστασία με ακροφύσια μειωμένης διασποράς
    PLANT_PROTECTION_PRECISION: "plantProtectionPrecision", // Φυτοπροστασία με χρήση γεωργίας ακριβείας
    MASS_TRAPPING: "massTrapping", //"Μαζική παγίδευση"
  },
  FERTILIZE: {
    HYDROLUBRICATION: "hydrolubrication", // Υδρολίπανση
    SOIL_BASIC: "soilBasic", // Λίπανση εδάφους
    SOIL_SURFACE: "soilSurface", // Λίπανση επιφάνειας εδάφους
    FOLIAR: "foliar", // Φυλλική λίπανση
  },
  DIAGNOSE: {
    ON_SITE_TESTING: "onSiteTesting", // Επιτόπιος διαγνωστικός έλεγχος
    SOIL_SAMPLING: "soilSampling", // Δειγματοληψία εδάφους
    LEAF_ANALYSIS: "leafAnalysis", // Φυλλοδιαγνωστική ανάλυση
    INSECT_MONITORING: "insectMonitoring", // Παρακολούθηση εντόμων
    LAB: "lab", // Εργαστηριακός έλεγχος
  },
  OTHER: {
    BALE: "bale", // Δεματοποίηση
    EMBEDDING: "embedding", // Ενσίρωση
    ERADICATION: "eradication", // Εκρίζωση
    HARVEST: "harvest", // Συγκομιδή
    LAWN: "lawn", // Χορτοκοπή
    MULCH: "mulch", // Εδαφοκάλυψη
    PLANTING: "planting", // Φύτευση
    PLOWING: "plowing", // Άροση
    PROPAGATING_MATERIAL: "propagatingMaterial", // Χρήση υγιούς πολλαπλασιαστικού υλικού
    PRUNING: "pruning", // Κλάδεμα
    REPRODUCTIVE_MATERIAL: "reproductiveMaterial", // Αναπαραγωγικό υλικό
    SOWING: "sowing", // Σπορά
    STIRRING: "stirring", // Ανάδευση
    SUN_DISINFECTION: "sunDisinfection", // Ηλιοαπολύμανση
    WEEDING: "weeding", // Βοτάνισμα
    WRAPPING: "wrapping", // Περιτύλιξη δεμάτων
  },
};
