import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import el from "moment/locale/el";
import { storage } from "utils/localStorage.js";

const MyI18NextProvider = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    moment.updateLocale("el", el);
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (lan) => {
      moment.locale(lan);
    };

    if (i18n) {
      const language = storage.getLanguagePreference();
      i18n.changeLanguage(language);
      // TODO: - Change it to i18n.changeLanguage() to automatically detect the browser's language
      i18n.on("languageChanged", handleLanguageChanged);
      i18n.on("initialized", handleLanguageChanged);
    }
    return () => {
      if (i18n) {
        i18n.off("languageChanged", handleLanguageChanged);
        i18n.off("initialized", handleLanguageChanged);
      }
    };
  }, [i18n]);

  return children;
};

export default MyI18NextProvider;
