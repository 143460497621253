import { lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TanstackProvider from "context/TanstackProvider";
import ThemeConfig from "./configs/ThemeConfig.json";
import MyI18NextProvider from "context/MyI18NextProvider";

const ProtectedRoute = lazy(() => import("./layout/ProtectedRoute.jsx"));

const App = () => {
  const theme = createTheme(ThemeConfig);

  return (
    <ThemeProvider theme={theme}>
      <MyI18NextProvider>
        <TanstackProvider>
          <Router>
            <ProtectedRoute />
          </Router>
        </TanstackProvider>
      </MyI18NextProvider>
    </ThemeProvider>
  );
};

export default App;
