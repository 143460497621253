import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubTitle from "../Typography/SubTitle";

const StyledAccordion = styled(Accordion)(() => ({
  "&.MuiAccordion-root::before": {
    opacity: 0,
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: "0px 0px 16px",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  "&.MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px",
  },
  "& .MuiAccordionSummary-content": {
    margin: "6px 0px",
    maxWidth: "100%",
    overflow: "hidden",
    "&.Mui-expanded": {
      margin: "6px 0px",
      maxWidth: "100%",
      overflow: "hidden",
    },
  },
  "& .summary-row": {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    justifyContent: "space-between",
    "& .title-container": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexGrow: 1,
    },
  },
  "& .title": {
    whiteSpace: "nowrap",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: "0px 16px 16px",
}));

export default function BasicAccordion({
  title,
  titleStyle = {},
  children,
  onChange,
  isInitiallyOpen,
  isOpen,
  titleIcon,
  titleAction,
  wrapperStyle,
  detailsStyle,
  summaryStyle,
  expandPosition = "end", // "start" | "end"
}) {
  const expandPositionStyle = {
    flexDirection: expandPosition === "start" ? "row-reverse" : "row",
  };

  return (
    <StyledAccordion
      onChange={onChange}
      defaultExpanded={isInitiallyOpen}
      style={wrapperStyle}
      expanded={isOpen}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ ...expandPositionStyle, ...summaryStyle }}
      >
        <div className="summary-row">
          {typeof title === "string" ? (
            <div className="title-container">
              {titleIcon}
              <SubTitle className="title" style={titleStyle}>
                {title}
              </SubTitle>
            </div>
          ) : (
            title
          )}
          {titleAction}
        </div>
      </StyledAccordionSummary>
      <StyledAccordionDetails sx={detailsStyle}>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
