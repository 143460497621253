import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import Title from "../../../Typography/Title";
import DateSpan from "../date-span/DateSpan";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";
import OtherActionOperationType from "./OtherActionOperationType";

const OtherActionSuggestion = ({ viewMode, isFullWidth, isProposedValue }) => {
  const { t } = useTranslation();
  const status = ACTION_STATUS.suggested;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;
  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Suggested")} />
        <DateSpan viewMode={viewMode} status={status} />
        <OtherActionOperationType
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
        <ActionModalDescriptionSection
          actionBaseName="otherAction"
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
      </div>
    </div>
  );
};
export default OtherActionSuggestion;
