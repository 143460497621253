import { styled } from "@mui/material";
import Modal from "components/Modals/Modal";

export const ActionModalStyled = styled(Modal)({
  "& .action-modal": {
    "&.action-modal-modal": {
      height: "90%",
      backgroundColor: "#F5F5F5",
      width: "960px",
      "@media (max-width:1000px)": {
        width: "800px",
      },
    },
  },
});
