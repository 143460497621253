import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import nestedUtils from "utils/nestedUtils";
import filterByTerm from "utils/filterByTerm";
import { useFieldsContext, useFieldsContextActions } from "./FieldsProvider";
import { useTranslation } from "react-i18next";

const filterField = (field, filters) => {
  if (filters.crops?.length) {
    const cropId = field.varietyRead.cropId;
    if (!filters.crops.includes(cropId)) {
      return false;
    }
  }
  if (filters.regions?.length) {
    const regionId = field.municipality.regionId.toString();
    if (!filters.regions.includes(regionId)) {
      return false;
    }
  }
  if (filters.prefectures?.length) {
    const prefectureId = field.municipality.prefectureId.toString();
    if (!filters.prefectures.includes(prefectureId)) {
      return false;
    }
  }
  return filterByTerm.field(field, filters.searchTerm);
};

const transformLocations = (locations) => {
  const transformItem = (item) => {
    const { value, parentIdPath, label, children } = item || {};
    let newLabel = label;
    let newValue = value;
    let newParentIdPath = parentIdPath;
    if (parentIdPath?.length === 2) {
      newLabel = label.split(",")[0];
      newValue = `mun_${value}`;
      newParentIdPath = [`reg_${parentIdPath[0]}`, `pref_${parentIdPath[1]}`];
    }
    if (parentIdPath?.length === 1) {
      newValue = `pref_${value}`;
      newParentIdPath = [`reg_${parentIdPath[0]}`];
    }
    if (parentIdPath?.length === 0) {
      newValue = `reg_${value}`;
    }
    const transformedItem = {
      ...item,
      parentIdPath: newParentIdPath,
      value: newValue,
      label: newLabel,
    };
    if (children && children?.length > 0) {
      transformedItem.children = children.map(transformItem);
    }
    return transformedItem;
  };

  return locations.map(transformItem);
};

const INITIAL_FILTERS_STATE = {
  regions: [],
  prefectures: [],
  municipalities: [],
  crops: [],
  varieties: [],
  status: [],
  ecoSchemes: [],
  cropType: "",
  searchTerm: "",
};

const statusAvailableFilters = (t) => [
  {
    label: t("KydTable.RegisteredUser"),
    value: "registeredKydUser",
    parentIdPath: [],
    children: [],
  },
  {
    label: t("KydTable.CamvioConnection"),
    value: "camvioConnection",
    parentIdPath: [],
    children: [],
  },
  { label: t("KydTable.OTP"), value: "otp", parentIdPath: [], children: [] },
  {
    label: t("KydTable.OPEKEPE"),
    value: "opekepe",
    parentIdPath: [],
    children: [],
  },
  {
    label: t("KydTable.SyncedFields"),
    value: "syncedFields",
    parentIdPath: [],
    children: [],
  },
];

const FieldsFiltersContext = createContext({
  filters: INITIAL_FILTERS_STATE,
  treeData: {
    crops: [],
    cropTypes: [],
    ecoSchemes: [],
    locations: [],
    status: [],
  },
  hasFields: false,
  getCropOptions: () => {},
  getVarietyOptions: () => {},
  getRegionOptions: () => {},
  getPrefectureOptions: () => {},
  getMunicipalityOptions: () => {},
  getCropTypesOptions: () => {},
  isReady: false,
  clear: () => {},
  /**
   *
   * @param {Object} data
   * @param {'regions' | 'prefectures' | 'crops' | 'searchTerm'} data.type
   * @param {string | Array<{value: string, label: string}>} data.newFilters
   * @param {Object} data.data
   */
  update: ({ type, newFilters, data }) => {},
  getFilteredFields: ({ fields, searchTerm }) => [],
});

const FieldsFiltersProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const navigate = useNavigate();
  const route = useLocation();
  const [isReady, setIsReady] = useState(false);

  const { fields } = useFieldsContext();
  const { getMyCrops, getMyLocations, getMyEcoSchemes } =
    useFieldsContextActions();

  const myCrops = useMemo(() => getMyCrops(), [getMyCrops]);
  const myLocations = useMemo(() => getMyLocations(), [getMyLocations]);
  const myStatus = useMemo(() => statusAvailableFilters(t), [t]);
  const myEcoSchemes = useMemo(() => getMyEcoSchemes(), [getMyEcoSchemes]);
  const hasFields = useMemo(() => Boolean(fields?.length), [fields]);

  const [filters, setFilters] = useState({ ...INITIAL_FILTERS_STATE });

  const getCropOptions = useCallback(() => {
    const allCrops = nestedUtils.getAll({
      data: myCrops || [],
      level: 0,
    });
    return allCrops.map((crop) => ({
      value: crop.value,
      label: crop.label,
    }));
  }, [myCrops]);

  const getVarietyOptions = useCallback(() => {
    let varieties = [];
    if (filters.crops?.length) {
      varieties = nestedUtils.getChildrenOfMultiple({
        data: myCrops,
        ids: filters.crops,
        level: 0,
      });
    } else {
      varieties = nestedUtils.getAll({
        data: myCrops || [],
        level: 1,
      });
    }
    return varieties.map((variety) => ({
      value: variety.value,
      label: variety.label,
    }));
  }, [myCrops, filters?.crops]);

  const getRegionOptions = useCallback(() => {
    const allRegions = nestedUtils.getAll({
      data: myLocations || [],
      level: 0,
    });
    return allRegions.map((region) => ({
      value: region.value,
      label: region.label,
    }));
  }, [myLocations]);

  const getPrefectureOptions = useCallback(() => {
    let prefectures = [];
    if (filters.regions?.length) {
      prefectures = nestedUtils.getChildrenOfMultiple({
        data: myLocations,
        ids: filters.regions,
        level: 0,
      });
    } else {
      prefectures = nestedUtils.getAll({
        data: myLocations || [],
        level: 1,
      });
    }
    return prefectures.map((pref) => ({
      value: pref.value,
      label: pref.label,
    }));
  }, [myLocations, filters?.regions]);

  const getMunicipalityOptions = useCallback(() => {
    const municipalityMap = new Map();

    for (const field of fields) {
      const { municipality } = field;
      if (!municipalityMap.has(municipality.id)) {
        municipalityMap.set(municipality.id, {
          value: municipality.id,
          label: isEn ? municipality.nameEn : municipality.nameGr,
        });
      }
    }
    return Array.from(municipalityMap.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  }, [fields]);

  const getCropTypesOptions = useCallback(() => {
    return [
      {
        label: t("DashboardPage.CropType.Radio.All"),
        value: "all",
      },
      {
        label: t("DashboardPage.CropType.Radio.Bio"),
        value: "bio",
      },
      {
        label: t("DashboardPage.CropType.Radio.Conventional"),
        value: "conventional",
      },
    ];
  }, [t]);

  const treeData = useMemo(() => {
    return {
      crops: myCrops ?? [],
      cropTypes: getCropTypesOptions(),
      ecoSchemes: myEcoSchemes ?? [],
      locations: myLocations ? transformLocations(myLocations) : [],
      status: myStatus,
    };
  }, [myLocations, myCrops, myStatus, myEcoSchemes, getCropTypesOptions]);

  const isTreeDataReady = useMemo(
    () => !!treeData && !!fields,
    [treeData, fields]
  );

  // const updateParams = useCallback(
  //   (data) => {
  //     const { regions, prefectures, crops, searchTerm, varieties } = data;
  //     const regionsString = regions?.join(",");
  //     const prefecturesString = prefectures?.join(",");
  //     const cropsString = crops?.join(",");
  //     const varietiesString = varieties?.join(",");
  //     const finalString = `${searchTerm ? `searchTerm=${searchTerm}&` : ""}${regionsString ? `regions=${regionsString}&` : ""}${prefecturesString ? `prefectures=${prefecturesString}&` : ""}${cropsString ? `crops=${cropsString}&` : ""}${varietiesString ? `crops=${varietiesString}&` : ""}`;
  //     navigate({
  //       search: `${finalString ? `?${finalString.slice(0, -1)}` : ""}`,
  //     });
  //   },
  //   [navigate]
  // );

  // This one is used in FieldsFilters, which is now unused
  const update = useCallback(
    ({ type, newFilters, data }) => {
      setIsReady(false);
      if (data) {
        setFilters(data);
        // updateParams(data);
      } else {
        if (type === "regions") {
          const municipalityOptions = getMunicipalityOptions();
          const municipalities = filters.municipalities.filter((munId) =>
            municipalityOptions.map((pref) => pref.value).includes(munId)
          );
          setFilters((prev) => ({
            ...prev,
            regions: newFilters,
            municipalities,
          }));
          // updateParams({ ...filters, regions: newFilters, prefectures });
        } else if (type === "crops") {
          const varietyOptions = getVarietyOptions();
          const varieties = filters.varieties.filter((varietyId) =>
            varietyOptions.map((vart) => vart.value).includes(varietyId)
          );
          setFilters((prev) => ({
            ...prev,
            crops: newFilters,
            varieties,
          }));
          // updateParams({ ...filters, crops: newFilters, varieties });
        } else if (type === "ecoSchemes") {
          setFilters((prev) => ({
            ...prev,
            ecoSchemes: newFilters,
          }));
          // updateParams({ ...filters, ecoSchemes: newFilters, varieties });
        } else if (type === "status") {
          setFilters((prev) => ({
            ...prev,
            status: newFilters,
          }));
          // updateParams({ ...filters, status: newFilters });
        } else if (type === "searchTerm") {
          setFilters((prev) => ({ ...prev, searchTerm: newFilters }));
        }
      }
      setIsReady(true);
    },
    [getMunicipalityOptions, filters, getVarietyOptions]
  );

  const clear = useCallback(() => {
    update({
      data: {
        ...INITIAL_FILTERS_STATE,
      },
    });
  }, [update]);

  const getFilteredFields = useCallback(
    ({ fields }) => {
      return fields.filter((field) => filterField(field, filters));
    },
    [filters]
  );

  // const isInfoExtractedFromUrl = useRef();
  // useEffect(() => {
  //   if (isInfoExtractedFromUrl.current) return;
  //   if (!isTreeDataReady) return;

  //   const params = new URLSearchParams(route.search);
  //   const regions = params.get("regions");
  //   const prefectures = params.get("prefectures");
  //   const crops = params.get("crops");
  //   const varieties = params.get("varieties");
  //   const searchTerm = params.get("searchTerm");

  //   setFilters({
  //     regions: regions ? regions.split(",") : [],
  //     prefectures: prefectures ? prefectures.split(",") : [],
  //     crops: crops ? crops.split(",") : [],
  //     varieties: varieties ? varieties.split(",") : [],
  //     searchTerm: searchTerm || "",
  //   });

  //   // if (locations || crops) {
  //   //   const updatedFilters = { locations: [], crops: [] };
  //   //   if (locations) {
  //   //     const locationIds = locations.split(",");
  //   //     const locationNodes = locationIds.map((id) => {
  //   //       const locationNode = findNode(treeData.locations, id);
  //   //       return locationNode
  //   //         ? { value: locationNode.value, label: locationNode.label }
  //   //         : null;
  //   //     });
  //   //     updatedFilters.locations = locationNodes.filter(Boolean);
  //   //   }
  //   //   if (crops) {
  //   //     const cropIds = crops.split(",");
  //   //     const cropNodes = cropIds.map((id) => {
  //   //       const cropNode = findNode(treeData.crops, id);
  //   //       return cropNode
  //   //         ? { value: cropNode.value, label: cropNode.label }
  //   //         : null;
  //   //     });
  //   //     updatedFilters.crops = cropNodes.filter(Boolean);
  //   //   }
  //   //   setFilters(updatedFilters);
  //   // }

  //   setIsReady(true);
  //   isInfoExtractedFromUrl.current = true;
  // }, [isTreeDataReady, route.search, treeData?.crops, treeData?.locations]);

  const value = useMemo(
    () => ({
      filters,
      treeData,
      // isReady,
      hasFields,
      isReady: isTreeDataReady,
      getCropOptions,
      getVarietyOptions,
      getRegionOptions,
      getPrefectureOptions,
      getMunicipalityOptions,
      getCropTypesOptions,
      clear,
      update,
      getFilteredFields,
    }),
    [
      filters,
      treeData,
      hasFields,
      // isReady,
      isTreeDataReady,
      getCropOptions,
      getVarietyOptions,
      getRegionOptions,
      getPrefectureOptions,
      getMunicipalityOptions,
      getCropTypesOptions,
      clear,
      update,
      getFilteredFields,
    ]
  );

  return (
    <FieldsFiltersContext.Provider value={value}>
      {children}
    </FieldsFiltersContext.Provider>
  );
};

const useFieldsFiltersContext = () => {
  const context = useContext(FieldsFiltersContext);

  if (!context) {
    throw new Error(
      "useFieldsFiltersContext must be used within a FieldsFiltersProvider"
    );
  }
  return useContext(FieldsFiltersContext);
};

export {
  useFieldsFiltersContext,
  FieldsFiltersProvider,
  INITIAL_FILTERS_STATE,
};
