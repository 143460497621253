import { Autocomplete } from "components";
import { Field, useField, useFormikContext } from "formik";

const FormAutocomplete = ({
  id,
  name,
  helperText,
  shouldHaveErrorBehaviorAfterSubmit,
  onChange,
  ...props
}) => {
  const [, meta] = useField(name);
  const metaError = name ? meta.error : null;

  const { submitCount } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && metaError
    : metaError;

  const finalHelperText = (shouldDisplayError && metaError) || helperText;

  return (
    <Field
      as={Autocomplete}
      id={id || name}
      name={name}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      {...props}
      {...(typeof onChange === "function" ? { onChange } : {})}
    />
  );
};

export default FormAutocomplete;
