import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, styled, tooltipClasses } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../../Typography/SubTitle";
import NormalText from "../../../Typography/NormalText";
import SmallText from "../../../Typography/SmallText";
import InfoIcon from "../../../../assets/icons/info-outlined.svg";

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
    color: "#43434a",
  },
});

const TooltipContentStyled = styled("div")({
  width: "500px",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "0.5rem",
  "& .title": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .limit-title": {
    fontWeight: 500,
    textAlign: "start",
  },
});

const HelperStyled = styled("div")({
  display: "flex",
  gap: "0.5rem",
  width: "max-content",
  marginTop: "0.5rem",
  "& .tooltip-link": {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
});

const FertilizeLimitsTooltip = ({ limits, onClose }) => {
  const { t } = useTranslation();
  const newRef = useRef(null);

  const handleOutsideClick = useCallback(
    (e) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        onClose?.();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <TooltipContentStyled ref={newRef}>
      <div className="title">
        <SubTitle>{t("ActionModal.FertilizeLimits")}</SubTitle>
        <CloseIcon sx={{ cursor: "pointer", fill: "#000" }} onClick={onClose} />
      </div>

      {limits?.map(({ name, symbol, description }) => (
        <Grid container key={symbol}>
          <Grid item xs={2.5}>
            <NormalText className="limit-title">{name}</NormalText>
          </Grid>
          <Grid item xs>
            <SmallText>{description}</SmallText>
          </Grid>
        </Grid>
      ))}
    </TooltipContentStyled>
  );
};

const FertilizeLimits = ({ limits }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const [showLimits, setShowLimits] = useState(false);

  const finalLimits = useMemo(() => {
    const onlyLimitsEntries = Object.keys(limits || {})
      .filter((key) => key.startsWith("legalLimit"))
      .reduce((obj, key) => {
        const properties = limits[key];
        obj[key] = {
          ...properties,
          name: isEn ? properties.nameEn : properties.nameGr,
          description: isEn
            ? properties.descriptionEn
            : properties.descriptionGr,
        };
        return obj;
      }, {});

    const limitsList = Object.values(onlyLimitsEntries).filter(
      (limit) => limit.name
    );

    return limitsList;
  }, [isEn, limits]);

  return finalLimits.length ? (
    <TooltipStyled
      open={showLimits}
      title={
        <FertilizeLimitsTooltip
          limits={finalLimits}
          onClose={() => setShowLimits(false)}
        />
      }
      placement="right-start"
    >
      <HelperStyled>
        <img alt="info" src={InfoIcon} />
        <span onClick={() => setShowLimits((prev) => !prev)}>
          <NormalText className="tooltip-link">
            {t("ActionModal.FertilizeLimits")}
          </NormalText>
        </span>
      </HelperStyled>
    </TooltipStyled>
  ) : (
    <></>
  );
};

export default FertilizeLimits;
