import { styled } from "@mui/material";

export const ActionReadyFormStyled = styled("form")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "20px 24px",
  height: "100%",
  "& .action-modal-form-inner": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    overflow: "auto",
    flexGrow: 1,
    paddingRight: "4px",
    paddingBottom: "40px",
    "& .action-modal-card": {
      padding: "24px",
      borderRadius: "4px",
      border: "1px solid #d1d1d1",
      backgroundColor: "#fff",
      display: "flex",
      gap: "16px",
      "& .action-modal-top-box": {
        width: "50%",
      },
    },
    "& .action-details-wrapper": {
      flexGrow: 1,
      "& .action-details-box": {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        minHeight: "100%",
        "& .details-half-width": {
          width: "50%",
        },
      },
      "& .action-details-both-box-wrapper": {
        minHeight: "100%",
        display: "grid",
        gap: "0.75rem",
        gridTemplateColumns: "1fr 1fr",
      },
      "& .action-details-quantity-input": {
        display: "flex",
        gap: "8px",
        alignItems: "flex-start",
      },
      "& .action-details-quantity-input.quantity-input-baseline": {
        alignItems: "baseline",
      },
      "& .action-details-column-multiple": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      },
      "& .action-details-box .field-description": {
        marginTop: "1rem",
        paddingLeft: "0.5rem",
        fontStyle: "italic",
      },
    },
  },
});
