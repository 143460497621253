import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormAutocomplete, FormRadioGroupInput, FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import DetailSection from "../DetailSection";
import DateSpan from "../date-span/DateSpan";
import QuantityInput from "../quantity/QuantityInput";
import useAvailableQuantityUnits from "../../utils/useAvailableOperationUnits";
import { actionInputName } from "../../utils/inputName";
import { useFilteredFertilizers } from "./useFertilizers";
import Limits from "./Limits";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const FertilizeSuggestion = ({
  viewMode,
  isFullWidth,
  isProposedValue,
  operations,
  fertilizers,
}) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { values } = useFormikContext();
  const status = ACTION_STATUS.suggested;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { availableQuantityUnits } = useAvailableQuantityUnits(
    values.taskId,
    values.fertilizeAction?.operationTypeId
  );

  const { fertilizerOptions, limits, handleFertilizerChange, bioSelections } =
    useFilteredFertilizers({
      fertilizers,
      operations,
      isProposed: isProposedValue,
    });

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Suggested")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection
          title={t("ActionModal.Fertilizer")}
          isRequired={!isViewMode}
        >
          <div className="action-details-column-multiple">
            <FormRadioGroupInput
              name={actionInputName("fertilizeAction", "bio", isProposedValue)}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={bioSelections.map(
                ({ value, label: { nameEn, nameGr } }) => ({
                  value,
                  label: isEn ? nameEn : nameGr,
                })
              )}
            />
            <FormSelectInput
              name={actionInputName(
                "fertilizeAction",
                "operationTypeId",
                isProposedValue
              )}
              placeholder={t("ActionModal.FertilizerOperation")}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={operations?.map(
                ({ operationTypeName, operationTypeId }) => ({
                  value: operationTypeId,
                  label: operationTypeName,
                })
              )}
            />
            <FormAutocomplete
              name={actionInputName(
                "fertilizeAction",
                "fertId",
                isProposedValue
              )}
              placeholder={t("ActionModal.FertilizerComposition")}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              isDisabled={
                !values.fertilizeAction?.operationTypeId ||
                values.fertilizeAction?.bio === undefined
              }
              options={fertilizerOptions}
              onChange={handleFertilizerChange}
            />
          </div>
        </DetailSection>
        <DetailSection
          title={t("ActionModal.Quantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            isProposedValue={isProposedValue}
            actionBaseName="fertilizeAction"
            quantityOptions={availableQuantityUnits}
          />
          <Limits limits={limits} />
        </DetailSection>
        <ActionModalDescriptionSection
          actionBaseName="fertilizeAction"
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
      </div>
    </div>
  );
};

export default FertilizeSuggestion;
