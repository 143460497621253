import { ACTION, OPERATIONS } from "constants/actions";

export const populateActionChoicesActions = (actions = []) => ({
  [ACTION.watering]: actions?.[0],
  [ACTION.drug]: actions?.[1],
  [ACTION.fertilize]: actions?.[2],
  [ACTION.diagnose]: actions?.[3],
  [ACTION.other]: actions?.[4],
});

export const populateActionChoicesTypes = (actionTypes = []) => ({
  prevention: {
    id: actionTypes[0][0],
    name: actionTypes[0][1],
  },
  treatment: {
    id: actionTypes[1][0],
    name: actionTypes[1][1],
  },
});

const populateWateringOperations = (operations = []) => ({
  [OPERATIONS.WATERING.SPRINKLERS]: operations?.[0],
  [OPERATIONS.WATERING.SUB_SURFACE_IRRIGATION]: operations?.[1],
  [OPERATIONS.WATERING.DROP_WATERING]: operations?.[2],
  [OPERATIONS.WATERING.ARTIFICIAL_RAIN]: operations?.[3],
  [OPERATIONS.WATERING.DROP_MINI_SPRINKLERS]: operations?.[4],
  [OPERATIONS.WATERING.SURFACE_IRRIGATION]: operations?.[5],
  [OPERATIONS.WATERING.OTHER]: operations?.[6],
});

const populateDrugOperations = (operations = []) => {
  let operationsDict = {};
  let operationKeys = Object.keys(OPERATIONS.DRUG);
  operationKeys.forEach((key) => {
    const operationFromApiCall = operations?.find(
      (op) => op.operationType?.trim() === OPERATIONS.DRUG[key]
    );
    if (operationFromApiCall) {
      operationsDict[OPERATIONS.DRUG[key]] = operationFromApiCall;
    }
  });
  return operationsDict;
};

const populateFertilizeOperations = (operations = []) => ({
  [OPERATIONS.FERTILIZE.HYDROLUBRICATION]: operations?.[0],
  [OPERATIONS.FERTILIZE.SOIL_BASIC]: operations?.[1],
  [OPERATIONS.FERTILIZE.SOIL_SURFACE]: operations?.[2],
  [OPERATIONS.FERTILIZE.FOLIAR]: operations?.[3],
});

const populateDiagnoseOperations = (operations = []) => ({
  [OPERATIONS.DIAGNOSE.ON_SITE_TESTING]: operations?.[0],
  [OPERATIONS.DIAGNOSE.SOIL_SAMPLING]: operations?.[1],
  [OPERATIONS.DIAGNOSE.LEAF_ANALYSIS]: operations?.[2],
  [OPERATIONS.DIAGNOSE.INSECT_MONITORING]: operations?.[3],
  [OPERATIONS.DIAGNOSE.LAB]: operations?.[4],
});

const populateOtherOperations = (operations = []) => {
  let operationsDict = {};
  let operationKeys = Object.keys(OPERATIONS.OTHER);
  operationKeys.forEach((key) => {
    const operationFromApiCall = operations?.find(
      (op) => op.operationType?.trim() === OPERATIONS.OTHER[key]
    );
    if (operationFromApiCall) {
      const { ploughingTypes, mulchTypes, ...restOperationFromApiCall } =
        operationFromApiCall;
      if (ploughingTypes) {
        restOperationFromApiCall.typeVariants = ploughingTypes?.map((type) => ({
          value: type.ploughingType,
          label: type.ploughingTypeName,
        }));
      } else if (mulchTypes) {
        restOperationFromApiCall.typeVariants = mulchTypes?.map((type) => ({
          value: type.mulchType,
          label: type.mulchTypeName,
        }));
      }

      operationsDict[OPERATIONS.OTHER[key]] = restOperationFromApiCall;
    }
  });
  return operationsDict;
};

export const populateActionChoicesOperations = (actionChoices = []) => {
  const wateringOperations = populateWateringOperations(
    actionChoices?.[ACTION.watering]?.operations
  );
  const drugOperations = populateDrugOperations(
    actionChoices?.[ACTION.drug]?.operations
  );
  const fertilizeOperations = populateFertilizeOperations(
    actionChoices?.[ACTION.fertilize]?.operations
  );
  const diagnoseOperations = populateDiagnoseOperations(
    actionChoices?.[ACTION.diagnose]?.operations
  );
  const otherOperations = populateOtherOperations(
    actionChoices?.[ACTION.other]?.operations
  );

  return {
    ...wateringOperations,
    ...drugOperations,
    ...fertilizeOperations,
    ...diagnoseOperations,
    ...otherOperations,
  };
};
