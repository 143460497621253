import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import { Tabs } from "components";
import Modal from "../Modal";
import { useTabs } from "hooks/useTabs";
import { useFieldInfoModalContext } from "context/FieldInfoModalProvider";
import { FETCH_STATE } from "constants/fetchState";
import FieldCamvioInfo from "./camvio/FieldCamvioInfo";
import FieldOpekepeInfo from "./opekepe/FieldOpekepeInfo";
import Layout from "./common/FieldInfoModalLayout";

const ModalStyled = styled(Modal)({
  "& .field-info-wrapper": {
    height: "750px",
    minWidth: "500px",
    maxWidth: "800px",
  },
});

const INFO_TABS_VALUES = {
  camvio: "camvio",
  opekepe: "opekepe",
};
const INFO_TABS = [
  {
    value: INFO_TABS_VALUES.camvio,
    label: "Camvio",
  },
  {
    value: INFO_TABS_VALUES.opekepe,
    label: "ΟΠΕΚΕΠΕ",
  },
];

const FieldInfoModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { tabs, activeTab, setActiveTab } = useTabs(INFO_TABS, "camvio");
  const { actionState, field } = useFieldInfoModalContext();

  const handleClose = () => {
    if (actionState !== FETCH_STATE.LOADING && typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <ModalStyled
      isOpen={isOpen}
      title={t("FieldInfo.Title")}
      onClose={handleClose}
      classNames={{ wrapper: "field-info-wrapper" }}
    >
      <Layout.ModalInner>
        {field?.isOpekepeConnected && (
          <Tabs
            tabs={tabs}
            value={activeTab}
            setValue={setActiveTab}
            variant="underline"
            onChange={setActiveTab}
          />
        )}
        {activeTab === INFO_TABS_VALUES.camvio && <FieldCamvioInfo />}
        {activeTab === INFO_TABS_VALUES.opekepe && <FieldOpekepeInfo />}
      </Layout.ModalInner>
    </ModalStyled>
  );
};

export default FieldInfoModal;
