import { camelToSnake } from "utils/camelSnakeTransformation";
import { transformObjectKeys } from "utils/transformObjectKeys";
import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getAllStresses = (cropId) => {
  const params = {
    ...(cropId ? { crop_id: cropId } : {}),
  };
  return axiosInstance.get(`${URLS.externalRequests}/agro/stresses/`, {
    params,
  });
};

const getActiveSubstances = (cropId, options = {}) => {
  const params = {
    ...(cropId ? { crop_id: cropId } : {}),
  };
  const finalOptions = { ...options, params };

  return axiosInstance.get(
    `${URLS.externalRequests}/agro/crop-agrochemicals/`,
    finalOptions
  );
};

const getFertilizers = (params = {}) => {
  const finalParams = transformObjectKeys(params, camelToSnake);
  return axiosInstance.get(`${URLS.externalRequests}/agro/fertilizers/`, {
    params: finalParams,
  });
};

export const cropsApi = {
  stresses: getAllStresses,
  activeSubstances: getActiveSubstances,
  fertilizers: getFertilizers,
};
