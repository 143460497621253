import clsx from "clsx";
import { Modal as MuiModal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import NormalText from "../Typography/NormalText";

const MuiModalStyled = styled(MuiModal)({
  "& .wrapper": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    backgroundColor: "#fbfbfe",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "700px",
    overflow: "hidden",
  },
  "& .title": {
    padding: "10px 20px 8px 20px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .title-text": {
    color: "#424242",
    flexGrow: "1",
  },
  "& .title-icon": {
    cursor: "pointer",
  },
  "& .body": {
    flexGrow: "1",
    overflow: "hidden",
  },
});

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  className,
  classNames = {},
}) => (
  <MuiModalStyled open={isOpen} onClose={onClose} className={className}>
    <div className={clsx("wrapper", classNames.wrapper)}>
      <div className="title">
        <NormalText className={clsx("title-text", classNames.titleText)}>
          {title || ""}
        </NormalText>
        <CloseIcon
          className={clsx("title-icon", classNames.titleIcon)}
          onClick={onClose}
        />
      </div>
      <div className={clsx("body", classNames.body)}>{children}</div>
    </div>
  </MuiModalStyled>
);

export default Modal;
